var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vue-tooltip-container" }, [
    _c(
      "div",
      {
        ref: "tooltip",
        staticClass: "vue-tooltip",
        class: { "--open": _vm.isOpen },
        on: { mouseenter: _vm.triggerOpen, mouseleave: _vm.triggerClose },
      },
      [
        _c(
          "div",
          {
            ref: "trigger",
            staticClass: "vue-tooltip__trigger",
            class: { "--no-body": !_vm.tooltipBodyExists },
            attrs: { tabindex: "0", "aria-describedby": _vm.tooltipBodyId },
            on: { focus: _vm.triggerOpen, blur: _vm.triggerClose },
          },
          [_vm._t("default")],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            ref: "body",
            staticClass: "vue-tooltip__body",
            class: { "--small": _vm.small },
            attrs: { id: _vm.tooltipBodyId, role: "tooltip" },
          },
          [
            _vm._t("body"),
            _vm._v(" "),
            _c("div", { domProps: { innerHTML: _vm._s(_vm.text) } }),
            _vm._v(" "),
            _vm.linkPresent
              ? _c("div", [
                  _c("a", {
                    attrs: { href: _vm.link, target: _vm.linkTarget },
                    domProps: { innerHTML: _vm._s(_vm.linkText) },
                  }),
                ])
              : _vm._e(),
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "vue-tooltip__arrow" }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }