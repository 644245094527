<template>
	<div class="vue-tooltip-container">
		<div ref="tooltip" class="vue-tooltip" :class="{ '--open': isOpen }" @mouseenter="triggerOpen" @mouseleave="triggerClose">
			<div ref="trigger" class="vue-tooltip__trigger" :class="{ '--no-body': !tooltipBodyExists }" tabindex="0" :aria-describedby="tooltipBodyId" @focus="triggerOpen" @blur="triggerClose">
				<slot />
			</div>
			<div :id="tooltipBodyId" ref="body" class="vue-tooltip__body" role="tooltip" :class="{ '--small': small }">
				<slot name="body" />
				<div v-html="text" />
				<div v-if="linkPresent">
					<a :href="link" :target="linkTarget" v-html="linkText" />
				</div>
			</div>
			<div class="vue-tooltip__arrow"></div>
		</div>
	</div>
</template>

<script>
import { createPopper } from '@popperjs/core';

export default {
	props: {
		text: {
			type: String,
			default: ''
		},
		small: {
			type: Boolean,
			default: false
		},
		link: {
			type: String,
			default: ''
		},
		linkText: {
			type: String,
			default: ''
		},
		placement: {
			type: String,
			default: 'top'
		},
		tooltipToggle: {
			type: Boolean,
			default: false
		},
		delay: {
			type: Number,
			default: 0
		}
	},

	data () {
		return {
			hovering: false,
			isOpen: false,
			popperInstance: null
		};
	},

	computed: {
		tooltipBodyId () {
			return `tooltip-body-${this._uid}`;
		},
		linkPresent () {
			return this.link && this.linkText;
		},
		linkTarget () {
			if (this.linkPresent && this.link.includes('app.getflywheel.com')) {
				return '_self';
			}
			return '_blank';
		},
		tooltipBodyExists () {
			return this.text || this.linkPresent || this.$slots.body;
		}
	},

	watch: {
		hovering (isHovering) {
			if (isHovering) {
				setTimeout(() => {
					if (this.hovering && this.tooltipBodyExists) {
						this.isOpen = true;
					}
				}, this.delay);
			} else {
				this.isOpen = false;
			}
		},
		isOpen (newIsOpen) {
			if (newIsOpen) {
				this.popperInstance.update();
			}
		},
		tooltipToggle: {
			immediate: true,
			handler (newVal) {
				if (newVal) {
					this.isOpen = true;
				} else {
					this.isOpen = false;
				}
			}
		}
	},

	mounted () {
		const tooltipTrigger = this.$refs.trigger;
		const tooltipBody = this.$refs.body;
		this.popperInstance = createPopper(tooltipTrigger, tooltipBody, {
			placement: this.placement,
			strategy: 'fixed',
			modifiers: [
				{
					name: 'offset',
					options: {
						offset: [0, 10]
					}
				}
			]
		});
	},

	methods: {
		triggerOpen () {
			this.hovering = true;
		},
		triggerClose () {
			this.hovering = false;
		}
	}
};
</script>

<style lang="scss" scoped>
@import "../../../main/styles/base/variables";
@import "../../../main/styles/base/mixins";

.vue-tooltip-container {
	display: inline-flex;
	align-items: center;
	z-index: 4;
}

.vue-tooltip {
	position: relative;
	margin: -1rem !important;
	padding: 1rem;

	@include state(open) {
		.vue-tooltip__body,
		.vue-tooltip__arrow {
			visibility: visible;
		}
	}

	&__trigger {
		cursor: pointer;
		display: flex;
		align-items: center;

		&.--no-body {
			cursor: default;
		}
	}

	&__body {
		visibility: hidden;
		position: absolute;
		margin: 0 auto;
		border-radius: $border-radius;
		box-shadow: 0 0 2px 0 rgba($gray-dark, .35);
		background: $white;
		padding: .75em;
		width: 15em;
		text-align: center;
		line-height: 1.4;
		color: $gray;
		font-size: 1.4rem;
		z-index: 99;
		font-weight: 300;

		// Links in tooltips should be underlined and bold
		a {
			text-decoration: underline;
			font-weight: 500;
		}

		@include state(small) {
			padding: .35em;
			width: 10em;
		}

		&[data-popper-placement^="top"] + .vue-tooltip__arrow {
			top: -.5rem;
			right: 0;
			left: 0;
			transform: rotate(45deg);
			margin: 0 auto;
		}

		&[data-popper-placement^="bottom"] + .vue-tooltip__arrow {
			right: 0;
			bottom: -.5rem;
			left: 0;
			transform: rotate(-135deg);
			margin: 0 auto;
		}

		&[data-popper-placement^="left"] + .vue-tooltip__arrow {
			top: 50%;
			left: -.5rem;
			transform: translateY(-50%) rotate(-45deg);
		}

		&[data-popper-placement^="right"] + .vue-tooltip__arrow {
			top: 50%;
			right: -.5rem;
			transform: translateY(-50%) rotate(135deg);
		}
	}

	&__arrow {
		content: "";
		position: absolute;
		visibility: hidden;
		z-index: 99;
		border-right: 1px solid $gray15;
		border-bottom: 1px solid $gray15;
		background: $white;
		width: .75em;
		height: .75em;
	}
}
</style>
